var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "desktop" }, [
    _c("h2", [_vm._v(_vm._s(_vm.advisor ? "Seja bem vindo!" : _vm.title))]),
    _c("h1", [
      _vm._v(
        "\n    " +
          _vm._s(
            _vm.advisor
              ? "Plataforma Digital do Seguro Garantia"
              : _vm.subtitle1
          ) +
          "\n    "
      ),
      _c("hr", { staticClass: "my-4" })
    ]),
    _c("p", { staticClass: "w-1/2 text-justify" }, [
      _vm._v(
        "\n    " +
          _vm._s(
            _vm.advisor
              ? "Uma plataforma tecnológica para você apoiar seus corretores parceiros de maneira fácil, intuitiva e 100% digital"
              : _vm.subtitle2
          ) +
          "\n  "
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }